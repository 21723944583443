<template>
  <div class="seller-modal">
    <!-- select stores -->
    <SelectStores></SelectStores>
    <!-- overlay -->
    <div id="overlay" class="overlay" @click="toggleSideBar('close')"></div>
    <!-- sidebar -->
    <div id="seller-sidebar" class="seller-sidebar">
      <div class="close-btn" @click="toggleSideBar('close')">X</div>
      <!-- logo -->
      <div class="logo">
        <img src="@/assets/website-use/logo_word.png" alt="" />
      </div>
      <UserInfoCp></UserInfoCp>
      <!-- 列表 -->
      <ul class="sidebar-list">
        <li
          class="mb-2 pointer hover"
          :class="{ active: currentPage === '商品' }"
          @click="go('商品')"
        >
          <img src="@/assets/icon/shopping-bag.png" alt="" />商品管理
        </li>
        <li
          class="mb-2 pointer hover"
          :class="{ active: currentPage === '訂單' }"
          @click="go('訂單')"
        >
          <img src="@/assets/icon/edit.png" alt="" />訂單管理
        </li>
        <li
          class="mb-2 pointer hover"
          :class="{ active: currentPage === '庫存' }"
          @click="go('庫存')"
        >
          <img src="@/assets/icon/box5.png" alt="" />庫存管理
        </li>
        <li
          class="mb-2 pointer hover"
          :class="{ active: currentPage === '叫貨' }"
          @click="go('叫貨')"
        >
          <img src="@/assets/icon/truck-side.png" alt="" />叫貨管理
        </li>
        <li
          class="mb-2 pointer hover"
          :class="{ active: currentPage === '顧客' }"
          @click="go('顧客')"
        >
          <img src="@/assets/icon/user.png" alt="" />顧客管理
        </li>
        <li
          class="mb-2 pointer hover"
          :class="{ active: currentPage === '對帳' }"
          @click="go('對帳')"
        >
          <img src="@/assets/icon/chart-histogram.png" alt="" />對帳出貨
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '行銷活動' }"
          @click="go('行銷活動')"
        >
          <p class="hover" @click="go('行銷活動')">
            <img src="@/assets/icon/users-alt.png" alt="" />行銷活動<i
              class="bi bi-caret-down-fill"
            ></i>
          </p>
          <div
            class="sidebar-second-class"
            :class="{ show: showWhichSecondClass === '行銷活動' }"
          >
            <ul>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '抽獎紀錄' }"
                @click="go('抽獎紀錄')"
              >
                <i class="bi bi-pencil-square"></i> 抽獎紀錄
              </li>
            </ul>
          </div>
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === 'Line' }"
          @click="go('Line')"
        >
          <p class="hover" @click="go('Line')">
            <img src="@/assets/icon/line2.png" alt="" />Line<i
              class="bi bi-caret-down-fill"
            ></i>
          </p>
          <div
            class="sidebar-second-class"
            :class="{ show: showWhichSecondClass === 'Line' }"
          >
            <ul>
              <!-- <li
                class="pointer hover"
                :class="{ active: currentPage === '直播列表' }"
                @click="go('直播列表')"
              >
                <i class="bi bi-pencil-square"></i> 直播列表
              </li> -->
              <li
                class="pointer hover"
                :class="{ active: currentPage === '待推播列表' }"
                @click="go('待推播列表')"
              >
                <i class="bi bi-pencil-square"></i> 待推播列表
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '推播歷程' }"
                @click="go('推播歷程')"
              >
                <i class="bi bi-pencil-square"></i> 推播歷程
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '許願管理' }"
                @click="go('許願管理')"
              >
                <i class="bi bi-pencil-square"></i> 許願管理
              </li>
            </ul>
          </div>
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '財務總覽' }"
          @click="go('財務總覽')"
        >
          <p class="hover" @click="go('財務總覽')">
            <img src="@/assets/icon/pulse.png" alt="" />財務總覽<i
              class="bi bi-caret-down-fill"
            ></i>
          </p>
          <div
            class="sidebar-second-class"
            :class="{ show: showWhichSecondClass === '財務總覽' }"
          >
            <ul>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '業績' }"
                @click="go('業績')"
              >
                <i class="bi bi-pencil-square"></i> 業績
              </li>
              <!-- <li
                class="pointer hover"
                :class="{ active: currentPage === '排行榜' }"
                @click="go('排行榜')"
              >
                <i class="bi bi-pencil-square"></i> 排行榜
              </li> -->
            </ul>
          </div>
        </li>
        <li class="mb-2 pointer">
          <p class="hover" @click="go('賣場')">
            <img src="@/assets/icon/shopping-cart.png" alt="" />賣場設定<i
              class="bi bi-caret-down-fill"
            ></i>
          </p>
          <div
            class="sidebar-second-class"
            :class="{ show: showWhichSecondClass === '賣場' }"
          >
            <ul>
              <li
                class="pointer hover"
                :class="{ active: currentPage === 'VIP/群組' }"
                @click="go('VIP/群組')"
              >
                <i class="bi bi-pencil-square"></i> VIP/群組
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '個人賣場' }"
                @click="go('個人賣場')"
              >
                <i class="bi bi-pencil-square"></i> 個人賣場
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '社群平台' }"
                @click="go('社群平台')"
              >
                <i class="bi bi-pencil-square"></i> 社群平台
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '供應商' }"
                @click="go('供應商')"
              >
                <i class="bi bi-pencil-square"></i> 供應商
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '結單/出貨/運送/付款' }"
                @click="go('結單/出貨/運送/付款')"
              >
                <i class="bi bi-pencil-square"></i> 結單/出貨/運送/付款
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '企業資訊' }"
                @click="go('企業資訊')"
              >
                <i class="bi bi-pencil-square"></i> 企業資訊
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '偏好設定' }"
                @click="go('偏好設定')"
              >
                <i class="bi bi-pencil-square"></i> 偏好設定
              </li>
              <!-- <li
                class="pointer hover"
                :class="{ active: currentPage === '優惠活動' }"
                @click="go('優惠活動')"
              >
                <i class="bi bi-pencil-square"></i> 優惠活動
              </li> -->
            </ul>
          </div>
        </li>
        <li style="height: 100px;">
        </li>
      </ul>
      <p class="sidebar-copy-right">©2022~2023優碼興科技股份有限公司</p>
    </div>
    <!-- sidebar toggle btn -->
    <div
      id="show-sidebar-btn"
      class="show-sidebar-btn hide-sidebar-btn d-flex align-items-center"
      @click="toggleSideBar('show')"
    >
      <span class="fw-bolder">{{ currentPage }}</span>
      <img src="@/assets/icon/angle-double-small-right.png" alt="" />
    </div>
    <!-- sidebar area -->
    <div id="sidebar-area" class="sidebar-area"></div>
    <!-- router view -->
    <div id="seller-content" class="seller-content">
      <div style="margin-top: 40px"></div>
      <router-view v-if="reloadRouterView"></router-view>
    </div>
  </div>
</template>

<script>
// components
import UserInfoCp from '@/components/sidebar/UserInfo_cp'
import SelectStores from '@/components/sidebar/SelectStores.vue'

export default {
  components: { UserInfoCp, SelectStores },
  data() {
    return {
      storeId: 0,
      serverToken: '',
      currentPage: '',
      reloadRouterView: true,
      // 顯示哪個第二階層
      showWhichSecondClass: '',
      // 紀錄目前視窗寬高
      currentViewSize: {
        width: 0,
        height: 0,
      },
      sidebarStatus: '',
    }
  },
  created() {
    this.checkCurrentPage()
  },
  mounted() {
    this.toggleSideBar('close')
    // 紀錄目前視窗寬高
    this.currentViewSize.width = window.innerWidth;
    this.currentViewSize.height = window.innerHeight;
    // 監聽
    window.onresize = () => {
      this.currentViewSize.width = window.innerWidth;
      this.currentViewSize.height = window.innerHeight;
    };
  },
  watch: {
    $route(to, from) {
      this.checkCurrentPage()
      // 如果更換賣場就更新畫面
      if (to.params.storeId != from.params.storeId) {
        this.reloadRouterView = false
        this.$nextTick(() => {
          this.reloadRouterView = true
        })
      }
    },
    // 當使用設備寬度小於 768 且 sidebar 為開啟時，底層頁面將禁止滑動 
    // 多此判斷是避免手機介面忽然橫屏
    'currentViewSize.width' (val) {
      if (val <= 768) {
        if (this.sidebarStatus === 'open') $('body').addClass('overflow-hidden')
        else $('body').removeClass('overflow-hidden')
      } else $('body').removeClass('overflow-hidden')
    },
    // 當使用設備寬度小於 768 且 sidebar 為開啟時，底層頁面將禁止滑動 
    sidebarStatus (val) {
      if (val === 'open') {
        if (this.currentViewSize.width <= 768) $('body').addClass('overflow-hidden')
      } else {
        if (this.currentViewSize.width <= 768) $('body').removeClass('overflow-hidden')
      }
    }
  },
  methods: {
    // sidebar 切換
    toggleSideBar(status) {
      if (status === 'close') {
        this.sidebarStatus = 'close'
        $('#seller-sidebar').addClass('close-sidebar')
        $('#show-sidebar-btn').removeClass('hide-sidebar-btn')
        $('#sidebar-area').addClass('close-sidebar-area')
        $('#seller-content').addClass('content-width100')
        $('#overlay').removeClass('overlay')
      } else {
        this.sidebarStatus = 'open'
        $('#seller-sidebar').removeClass('close-sidebar')
        $('#show-sidebar-btn').addClass('hide-sidebar-btn')
        $('#sidebar-area').removeClass('close-sidebar-area')
        $('#seller-content').removeClass('content-width100')
        $('#overlay').addClass('overlay')
      }
    },
    // 前往
    go(where) {
      switch (where) {
        case "商品":
          this.$router.push({name: 'Merchandise'})
          this.toggleSideBar('close')
          break;
        case "訂單":
          this.$router.push({name: 'MerchOrder'})
          this.toggleSideBar('close')
          break;
        case "庫存":
          this.$router.push({name: 'Stock'})
          this.toggleSideBar('close')
          break;
        case "叫貨":
          this.$router.push({name: 'PurchaseOrder'})
          this.toggleSideBar('close')
          break;
        case "顧客":
          this.$router.push({name: 'Participant'})
          this.toggleSideBar('close')
          break;
        case "對帳":
          this.$router.push({name: 'Bill'})
          this.toggleSideBar('close')
          break;
        case "Line":
          this.showWhichSecondClass = 'Line'
          break;
        case "直播列表":
          this.$router.push({name: 'Live'})
          this.toggleSideBar('close')
          break;
        case "待推播列表":
          this.$router.push({name: 'WaitingPush'})
          this.toggleSideBar('close')
          break;
        case "許願管理":
          this.$router.push({name: 'MakeWishes'})
          this.toggleSideBar('close')
          break;
        case "財務總覽":
          this.showWhichSecondClass = '財務總覽'
          break;
        case "業績":
          this.$router.push({name: 'Performance'})
          this.toggleSideBar('close')
          break;
        case "排行榜":
          this.$router.push({name: 'Ranking'})
          this.toggleSideBar('close')
          break;
        case "推播歷程":
          this.$router.push({name: 'PushingHistories'})
          this.toggleSideBar('close')
          break;
        case "賣場":
          this.showWhichSecondClass = '賣場' 
          break;
        case "VIP/群組":
          this.$router.push({name: 'VipsAndGroups'})
          this.toggleSideBar('close')
          break;
        case "個人賣場":
          this.$router.push({name: 'PersonalStore'})
          this.toggleSideBar('close')
          break;
        case "社群平台":
          this.$router.push({name: 'Social'})
          this.toggleSideBar('close')
          break;
        case "供應商":
          this.$router.push({name: 'Suppliers'})
          this.toggleSideBar('close')
          break;
        case "結單/出貨/運送/付款":
          this.$router.push({name: 'ShippingPayments'})
          this.toggleSideBar('close')
          break;
        case "企業資訊":
          this.$router.push({name: 'Business'})
          this.toggleSideBar('close')
          break;
        case "偏好設定":
          this.$router.push({name: 'PS_Merchandise'})
          this.toggleSideBar('close')
          break;
        case "優惠活動":
          this.$router.push({name: 'Promotions'})
          this.toggleSideBar('close')
          break;
        case "行銷活動":
          this.showWhichSecondClass = '行銷活動'
          break;
        case "抽獎紀錄":
          this.$router.push({name: 'LotteryRecord'})
          this.toggleSideBar('close')
          break;
      }
    },
    // 檢查目前在哪一頁
    checkCurrentPage() {
      this.currentPage = ''
      switch (this.$route.name) {
        case "Merchandise":
          this.currentPage = '商品'
          break;
        case "MerchOrder":
          this.currentPage = '訂單'
          break;
        case "Stock":
          this.currentPage = '庫存'
          break;
        case "PurchaseOrder":
          this.currentPage = '叫貨'
          break;
        case "Participant":
          this.currentPage = '顧客'
          break;
        case "Bill":
          this.currentPage = '對帳'
          break;
        case "Performance":
          this.currentPage = '業績'
          break;
        case "Ranking":
          this.currentPage = '排行榜'
          break;
        case "PushingHistories":
          this.currentPage = '推播歷程'
          break;
        case "Live":
          this.currentPage = '直播列表'
          break;
        case "WaitingPush":
          this.currentPage = '待推播列表'
          break;
        case "MakeWishes":
          this.currentPage = '許願管理'
          break;
        case "VipsAndGroups":
          this.currentPage = 'VIP/群組'
          break;
        case "PersonalStore":
          this.currentPage = '個人賣場'
          break;
        case "Social":
          this.currentPage = '社群平台'
          break;
        case "Suppliers":
          this.currentPage = '供應商'
          break;
        case "ShippingPayments":
          this.currentPage = '結單/出貨/運送/付款'
          break;
        case "Business":
          this.currentPage = '企業資訊'
          break;
        case "PS_Merchandise":
          this.currentPage = '偏好設定'
          break;
        case "PS_MerchOrder":
          this.currentPage = '偏好設定'
          break;
        case "PS_Stock":
          this.currentPage = '偏好設定'
          break;
        case "PS_PurchaseOrder":
          this.currentPage = '偏好設定'
          break;
        case "PS_Participant":
          this.currentPage = '偏好設定'
          break;
        case "PS_Bill":
          this.currentPage = '偏好設定'
          break;
        case "PS_Shipper":
          this.currentPage = '偏好設定'
          break;
        case "PS_Line":
          this.currentPage = '偏好設定'
          break;
        case "Promotions":
          this.currentPage = '優惠活動'
          break;
        case "LotteryRecord":
          this.currentPage = '抽獎紀錄'
          break;
      }
    },
  }
}
</script>